<template>
  <AppLayout>
    <template v-slot:appContent>
      <div>
        <div class="d-flex justify-content-between align-items-center pt-2 pt-md-2 pt-xl-0 ">
          <h4 class="">Product Categories/Create</h4>
          <div>
            <router-link :to="{...previousRoute}">
              <span class="glow d-flex align-items-center"><i class='bx bx-undo'></i>&nbsp;Back</span>
            </router-link>
          </div>
        </div>

        <div class="card">
          <div class="card-body">
            <form action="">
              <div class="row">
                <div class="col-12">
                  <div class="form-group">
                    <div class="">
                      <label>Category Name</label>
                      <input v-model="postProductCategoryData.name" type="text" class="form-control"
                             placeholder="Enter Product Category Name" name="productName">
                      <div class="text-danger" v-if="errors.name">{{ errors.name }}</div>
                    </div>
                  </div>
                </div>
                <div class="col-12 col-sm-12 col-md-6">
                  <div class="form-group">
                    <div class="controls">
                      <label>Parents Category</label>
                      <VueMultiselect :allowEmpty="false" v-model="selectedCategory" class="multiselect-blue"
                                      :options="categoryOptions"
                                      :close-on-select="true" placeholder="Select Category" label="name" track-by="name"
                                      :show-labels="false"/>
                      <div class="text-danger" v-if="errors.category">{{ errors.category }}</div>
                    </div>
                  </div>
                </div>
                <div class="col-12 col-sm-12 col-md-6">
                  <div class="form-group">
                    <div class="controls">
                      <label>Status</label>
                      <VueMultiselect :allowEmpty="false" v-model="selectedStatus" class="multiselect-blue"
                                      :options="statusOptions"
                                      :close-on-select="true" placeholder="Select Status" label="name" track-by="name"
                                      :show-labels="false"/>
                      <div class="text-danger" v-if="errors.status">{{ errors.status }}</div>
                    </div>
                  </div>
                </div>
                <div class="col-12">
                  <div class="form-group">
                    <div class="">
                      <label>Description</label>
                      <textarea v-model="postProductCategoryData.description"
                                class="form-control"
                                aria-placeholder="Enter Product Category description"> </textarea>
                      <div class="text-danger" v-if="errors.description">{{ errors.description }}</div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col-12 ">
                  <div class="d-flex justify-content-end">
                    <button @click="createSingleProductCategory"
                            type="button" class="btn btn-primary update-todo px-4">Add Product
                    </button>
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </template>
  </AppLayout>
</template>

<script>
import AppLayout from '@/layouts/backEnd/AppLayout.vue';
import VueMultiselect from 'vue-multiselect';
import {mapActions, mapGetters} from "vuex";

import ShowToastMessage from "@/components/backEnd/mixins/ShowToastMessage";
import Loader from "@/components/backEnd/mixins/Loader";
import Authorization from "@/components/backEnd/mixins/Authorization";

export default {
  name: "ProductCategoryCreate",
  mixins: [ShowToastMessage, Loader, Authorization],
  components: {
    AppLayout,
    VueMultiselect
  },
  data() {
    return {
      getProductCategoriesParams: {
        with_relation: ['children'],
        status: 1,
        order_by_name: 'ASC',
      },
      description: '',
      selectedStatus: {
        value: '',
        name: 'None'
      },
      selectedCategory: {
        value: '',
        name: 'None'
      },
      postProductCategoryData: {
        name: '',
        parent_id: '',
        slug: '',
        status: '',
        description: '',
      },
      errors: {
        name: '',
        category: '',
        status: '',
        description: ''
      }
    }
  },
  watch: {
    selectedStatus() {
      this.postProductCategoryData.status = this.selectedStatus.value;
    },
    selectedCategory(selectedCategory) {
      this.postProductCategoryData.parent_id = selectedCategory.value;
    }
  },

  computed: {
    ...mapGetters({
      previousRoute: 'previousRoute',
      productCategories: 'appProductCategories/productCategories'
    }),
    redirectRouteName() {
      return this.can('product-category-view-any') ? 'appProductCategoryList' : 'appProductCategoryCreate';
    },
    categoryOptions() {
      let types = this.$store.getters['appProductCategories/productCategories'] ? this.$store.getters['appProductCategories/productCategories'].map((type) => {
        return {
          value: type.id,
          name: type.name,
        };
      }) : [];

      return [
        {
          value: '',
          name: 'None'
        },
        ...types
      ];
    },
    statusOptions() {
      return [
        {
          value: 0,
          name: 'Inactive'
        },
        {
          value: 1,
          name: 'Active'
        },
      ]
    },
  },
  methods: {
    ...mapActions({
      postProductCategory: 'appProductCategories/postProductCategory',
      getProductCategories: 'appProductCategories/getProductCategories',
    }),

    async getProductCategoryList() {
      await this.getProductCategories(this.getProductCategoriesParams);
    },
    async createSingleProductCategory() {
      await this.loader(true);
      await this.postProductCategory(this.postProductCategoryData).then(async (response) => {
        await this.loader(false);
        if (response.status === 201) {
          const toastObj = {message: 'Product Category created successful.', type: 'success'};
          this.showToastMessage(toastObj);
          await this.$router.replace({name: this.redirectRouteName});
        }

        this.errors.name = response?.errors?.name[0] ?? '';
        this.errors.status = response?.errors?.status[0] ?? '';
        this.errors.description = response?.errors?.description[0] ?? '';
        this.errors.category = response?.errors?.parent_id[0] ?? '';
        if (response.message) {
          this.showToastMessage(response);
        }
      });
    },

  },
  async mounted() {
    await this.loader(true);
    await this.getProductCategoryList();
    await this.loader(false);

  },
}
</script>

<style scoped>

</style>